import request from '@/utils/request'

// 查询用户
export function findUsers(params) {
  return request({
    url: `/users`,
    method: 'get',
    params: params
  })
}

// 创建用户
export function createUser(data) {
  return request({
    url: `/users`,
    method: 'post',
    data
  })
}

// 查询单个用户
export function findUser(userId) {
  return request({
    url: `/users/${userId}`,
    method: 'get'
  })
}

// 查询单个用户表单值
export function findUserForm(userId) {
  return request({
    url: `/users/${userId}/form`,
    method: 'get'
  })
}

// 查询登录用户基本信息
export function findUserBasicInfo(userId) {
  return request({
    url: `/users/${userId}/basic_info`,
    method: 'get'
  })
}

// 更新用户
export function updateUser(userId, data) {
  return request({
    url: `/users/${userId}`,
    method: 'put',
    data
  })
}

// 更新用户有效性
export function updateUserEffective(userId, data) {
  return request({
    url: `/users/${userId}/effective`,
    method: 'patch',
    data
  })
}

// 查询所有有效的销售选项
export function findEffectiveSaleOptions() {
  return request({
    url: `/sales/effective/options`,
    method: 'get'
  })
}

// 查询所有销售选项(包括已失效的销售)
export function findSaleOptions() {
  return request({
    url: `/sales/options`,
    method: 'get'
  })
}

// 查询所有有效的销售助理选项
export function findEffectiveSalesAssistantOptions() {
  return request({
    url: `/sales_assistant/effective/options`,
    method: 'get'
  })
}

// 修改销售跟踪账号权限
export function updateUserTrackRegisteredAccount(userId, data) {
  return request({
    url: `/users/${userId}/track_registered_account`,
    method: 'patch',
    data
  })
}

// 更新账户的username
export function updateUserSelfUsername(userId, data) {
  return request({
    url: `users/${userId}/username`,
    method: 'patch',
    data
  })
}

// 更新账户的姓名
export function updateUserSelfFullName(userId, data) {
  return request({
    url: `users/${userId}/full_name`,
    method: 'patch',
    data
  })
}

// 更新账户的密码
export function updateUserSelfPassword(userId, data) {
  return request({
    url: `/users/${userId}/password`,
    method: 'patch',
    data
  })
}

// 更新账户的绑定手机
export function updateUserSelfPhoneNumber(userId, data) {
  return request({
    url: `users/${userId}/phone_number`,
    method: 'patch',
    data
  })
}

// 更新账户的绑定邮箱
export function updateUserSelfEmail(userId, data) {
  return request({
    url: `users/${userId}/email`,
    method: 'patch',
    data
  })
}

// 验证手机号是否已被注册
export function verifyPhoneNumber(data) {
  return request({
    url: `/register/verify_phone_number`,
    method: 'post',
    data
  })
}

// 获取有注册账号跟踪权限的有效销售
export function findCanTrackRegisteredAccountSaleOptions() {
  return request({
    url: `/sales/track_registered_account/options`,
    method: 'get'
  })
}

// 查询用户的手机号码
export function findUserEncryptedPhoneNumber(userId) {
  return request({
    url: `/users/${userId}/encrypted_phone_number`,
    method: 'get'
  })
}

// 查询所有平台用户选项(包括已失效)
export function findManageUserOptions() {
  return request({
    url: `/users/manager/options`,
    method: 'get'
  })
}

// 查询销售下的客户
export function findSaleAgents(userId, params) {
  return request({
    url: `/users/${userId}/sale_agents`,
    method: 'get',
    params: params
  })
}
