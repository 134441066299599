<template>
  <div>
    <search-user @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('users/new')"
        >新增</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <div slot="username" slot-scope="username, record">
        <a @click="showInfoModal(record.id)">{{ username }}</a>
      </div>

      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          :disabled="!isCanUpdateEffective(record.id, record.role_slug)"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>

      <div slot="agents_count" slot-scope="agents_count, record">
        <span v-if="(record.role_slug === 'sale' || record.role_slug === 'sale_assistant') && agents_count > 0 && isShowMenu('agents')">
          <a @click="showAgentsModal(record.id)"> {{ agents_count }} </a>
        </span>
        <span v-else-if="(record.role_slug === 'sale' || record.role_slug === 'sale_assistant') && agents_count == 0">0</span>
        <span v-else>-</span>
      </div>

      <div slot="track_registered_account" slot-scope="track_registered_account, record">
        <a-switch
          v-if="record.role_slug === 'sale' || record.role_slug === 'sale_assistant'"
          :checked="track_registered_account"
          :loading="editingTrackRegisteredAccountId === record.id"
          @change="(checked) => changeTrackRegisteredAccount(checked, record)"
        />
        <span v-else>-</span>
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isCanEdit(record.id, record.role_slug)">编辑</a>
          <a @click="switchUser(record.id)" v-if="isCanSwitchUser(record)">登录</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增用户模态框 -->
    <new-user
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑用户模态框 -->
    <edit-user
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 用户详情模态框 -->
    <show-user
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <!--销售客户数模态框 -->
    <sale-agents
      v-if="isShowAgentsModal"
      :visible.sync="isShowAgentsModal"
      :id="showingSaleId"
    />
  </div>
</template>

<script>
import { findUsers, updateUserEffective, updateUserTrackRegisteredAccount } from '@/api/user'
import Pagination from '@/components/Pagination/index'
import SearchUser from '@/views/users/Search'
import { hasPermission } from '@/utils/permission'
import { switchUser } from '@/api/switch_user'
import { getOriginalUserUid } from '@/utils/token'

export default {
  name: 'UserList',
  components: {
    SearchUser,
    Pagination,
    NewUser: () => import('@/views/users/New'),
    EditUser: () => import('@/views/users/Edit'),
    ShowUser: () => import('@/views/users/Show'),
    SaleAgents: () => import('@/views/users/sale_agents/index')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowAgentsModal: false, // 销售是否显示客户数弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingTrackRegisteredAccountId: 0, // 正在修改跟踪账号权限性的记录id
      editingId: 0, // 正在编辑的记录id
      showingId: 0, // 正在查看详情的记录id
      showingSaleId: 0, // 销售正在查看客户数记录id
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1'
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '登录账号',
          dataIndex: 'username',
          width: 150,
          fixed: 'left',
          scopedSlots: { customRender: 'username' }
        },
        {
          title: '姓名',
          width: 150,
          dataIndex: 'full_name'
        },
        {
          title: '手机号',
          width: 120,
          dataIndex: 'phone_number'
        },
        {
          title: '邮箱',
          width: 220,
          dataIndex: 'email'
        },
        {
          title: '有效性',
          width: 65,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '角色',
          width: 100,
          dataIndex: 'role'
        },
        {
          title: '客户数',
          width: 100,
          scopedSlots: { customRender: 'agents_count' },
          dataIndex: 'agents_count'
        },
        {
          title: '跟踪账号权限',
          width: 100,
          scopedSlots: { customRender: 'track_registered_account' },
          dataIndex: 'track_registered_account'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 180,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '登录次数',
          width: 100,
          dataIndex: 'sign_in_count'
        },
        {
          title: '最近登录时间',
          width: 180,
          dataIndex: 'last_sign_in_at'
        },
        {
          title: '操作',
          width: 80,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('users/edit')
    }
  },
  methods: {
    showAgentsModal(saleId) {
      this.showingSaleId = saleId
      this.isShowAgentsModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 是否可以
    isCanSwitchUser(record) {
      // 失效的账号不能登录
      if (!record.effective) {
        return false
      }

      // 管理员不能操作超级管理员
      if (this.$store.getters.userRole === 'admin' && record.role_slug === 'super_admin') {
        return false
      }

      // 相同角色不能操作
      return this.$store.getters.userRole !== record.role_slug
    },

    // 一键登录
    switchUser(userId) {
      switchUser({ user_id: userId, original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          window.location.href = res.data.redirect_url
        }
      })
    },

    // 是否可以编辑某条记录
    isCanEdit(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }

      // 管理员不能编辑超级管理员
      if (this.$store.getters.userRole === 'admin' && recordRole === 'super_admin') {
        return false
      }

      // 相同角色不能操作(除了编辑自己)
      if (this.$store.getters.userRole === recordRole && this.$store.getters.userId !== recordId) {
        return false
      }

      return true
    },

    // 是否可以编辑某条记录有效性
    isCanUpdateEffective(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }

      // 不能操作自己
      if (this.$store.getters.userId === recordId) {
        return false
      }

      // 管理员不能操作超级管理员
      if (this.$store.getters.userRole === 'admin' && recordRole === 'super_admin') {
        return false
      }

      // 相同角色不能操作
      if (this.$store.getters.userRole === recordRole) {
        return false
      }

      return true
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    showInfoModal(userId) {
      this.showingId = userId
      this.isShowInfoModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findUsers(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将账号生效吗?' : '确定失效账号吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateUserEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    changeTrackRegisteredAccount(checked, record) {
      const vm = this
      const trackRegisteredAccount = checked
      this.$confirm({
        title: trackRegisteredAccount ? '确定开启跟踪账号权限吗?' : '确定关闭跟踪账号权限吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingTrackRegisteredAccountId = record.id
          updateUserTrackRegisteredAccount(record.id, { track_registered_account: trackRegisteredAccount })
            .then((res) => {
              vm.editingTrackRegisteredAccountId = 0
              if (res.code === 0) {
                record.track_registered_account = checked
              }
            })
            .catch(() => {
              vm.editingTrackRegisteredAccountId = 0
            })
        }
      })
    }
  }
}
</script>
