<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="登录账号或姓名">
            <a-input
              v-decorator="['username_or_full_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="角色">
            <a-select
              v-decorator="['role_id']"
              :loading="roleSelectLoading"
              allow-clear
            >
              <a-select-option
                v-for="role in roleOptions"
                :key="role.id"
                :value="role.id"
              >
                {{ role.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="有效性">
            <a-select
              v-decorator="['effective', { initialValue: '1' }]"
              allow-clear
            >
              <a-select-option key="1" value="1">
                有效
              </a-select-option>
              <a-select-option key="0" value="0">
                无效
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findRoleOptions } from '@/api/role'

export default {
  name: 'SearchUser',
  data() {
    return {
      roleOptions: [],
      form: this.$form.createForm(this, { name: 'user_search' })
    }
  },
  created() {
    this.fetchRoleOptions()
  },
  computed: {
    roleSelectLoading() {
      return this.roleOptions.length === 0
    }
  },
  methods: {
    // 加载角色选项
    fetchRoleOptions() {
      findRoleOptions().then((res) => {
        this.roleOptions = res.data
      })
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
