import request from '@/utils/request'

// 查询所有平台角色
export function findManagerRoles(params) {
  return request({
    url: `/roles/manager`,
    method: 'get',
    params
  })
}

// 更新角色有效性
export function updateRoleEffective(id, data) {
  return request({
    url: `/roles/${id}/effective`,
    method: 'patch',
    data
  })
}

// 获取除超级管理员外的所有平台角色
export function findRoleOptions() {
  return request({
    url: `/roles/options`,
    method: 'get'
  })
}
